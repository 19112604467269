import type {TypeChannelInfo} from "@/channel";

let baseUrl = `${window.location.protocol}//test-api.careinsur.com`;
let wechatAppid = 'wx6c5133163b71afaa';
if (process.env.REACT_APP_ENV === 'production') {
    baseUrl = `${window.location.protocol}//api.careinsur.com`;
    wechatAppid = 'wxca0a409bb16f7257'
}

const defaultChannelInfo: TypeChannelInfo = {
    channelId: '152007922691473408',
    terminal: 'weChat'
}



export {
    baseUrl,
    wechatAppid,
    defaultChannelInfo,
}
