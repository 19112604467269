import React, {Component} from 'react';
import style from './Footer.module.less';
import MyLink from "./MyLink";

class Footer extends Component {
    render() {
        return (
            <div className={style.footer}>
                <p>
                    <MyLink to="/about-us">关于我们</MyLink>
                    &nbsp;
                    <span>|</span>
                    &nbsp;
                    <MyLink to="/user-agreement">用户协议</MyLink>
                    &nbsp;
                    <span>|</span>
                    &nbsp;
                    <MyLink to="/about-us">经营许可</MyLink>
                </p>
                <p>
                    关爱保险经纪&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">京ICP备19004895号-3</a>
                </p>
            </div>
        );
    }
}

export default Footer;
