import React, {Component, Suspense, lazy} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import HomeLayout from '@/pages/Index';
import WechatCode from '@/pages/OtherPage/WechatCode';
import type {ReactElement, ReactNode} from 'react';
import {isWechat, wechatLogin} from "@/utils";
import { monitorLogData } from '@/api/recall';
// import type {RouteComponentProps} from 'react-router-dom';

// import { TransitionGroup, CSSTransition } from 'react-transition-group';



export type TypeRoute = {
    path: string | string[]; // path
    name?: string; // 名称
    component: Promise<ReactElement> | Promise<ReactNode> | ReactNode | any;
    auth?: boolean; // 是否登录验证
    lazy?: boolean; // 是否懒加载路由 默认是true
    exact?: boolean;
    children?: TypeRoute[];
    title?: string; // 页面title
    navName?: string;
}
class LazyLoading extends Component {
  constructor(props: any) {
    super(props);
    this.loading.start();
    monitorLogData({
      eventCode: window.location.pathname,
      eventName: document.title,
    });
  }

  componentWillUnmount() {
    this.loading.set(0.5);
  }

  render() {
    return null;
  }
}

@inject("globalStore")
@observer
// // @ts-ignore
// @withRouter
class MyRouter extends Component<any> {
  componentDidMount() {
    document.body.addEventListener("click", (e) => {
        const target: any = e.target;
      const felog = target?.dataset?.felog;
      if (felog) {
        monitorLogData({
          eventCode: `${window.location.pathname}__buttonClick`,
          eventName: `${document.title}_${target.innerText}_点击`,
        });
      }
    });
  }
    private routes: TypeRoute[] = [
        {path: '/home', component: HomeLayout,  children: [
            {path: '/home/index', name: 'index', navName: '首页', title: '首页', component: () => import(/* webpackChunkName: 'Home' */'@/pages/Index/Home')},
            {path: '/home/insurance', name: 'insurance', navName: '保险保障', title: '保险保障', component: () => import(/* webpackChunkName: 'Insurance' */'@/pages/Index/Insurance')},
            // {path: '/home/service', name: 'service', navName: '健康服务', title: '健康服务', component: () => import(/* webpackChunkName: 'Service' */'@/pages/Index/Service')},
            {path: '/home/me', auth: true, name: 'me', navName: '个人中心', title: '个人中心', component: () => import(/* webpackChunkName: 'Me' */'@/pages/Index/Me')},
        ]},
        {path: '/ProductDetail/:code', title: "产品详情页", component: () => import(/* webpackChunkName: 'ProductDetail' */'@/pages/Product/ProductDetail')},
        {path: '/login', title: "登录", component: () => import(/* webpackChunkName: 'Login' */'@/pages/Login')},
        {path: '/wechat', title: "微信授权", lazy: false, component: WechatCode},
        {path: '/myInsurance', exact: true, auth: true, title: "我的保单", component: () => import(/* webpackChunkName: 'MeBaodan' */'@/pages/Me/MeBaodan')},
        {path: '/myInsurance/detail', auth: true, title: "我的保单详情", component: () => import(/* webpackChunkName: 'MeBaodan' */'@/pages/Me/MeBaodanDetail')},
        {path: '/myInsurance/endorsement', auth: true, title: "我的批单", component: () => import(/* webpackChunkName: 'MeBaodan' */'@/pages/Me/MeBaodanEndorsement')},
        {path: '/myAppointment', exact: true, auth: true, title: "我的预约", component: () => import(/* webpackChunkName: 'MeYuyue' */'@/pages/Me/MeYuyue')},
        {path: '/myAppointment/detail', auth: true, title: "我的预约详情", component: () => import(/* webpackChunkName: 'MeYuyueDetail' */'@/pages/Me/MeYuyueDetail')},
        {path: '/myClaim', auth: true, title: "我的理赔", component: () => import(/* webpackChunkName: 'MeLiPei' */'@/pages/Me/MeLiPei')},
        {path: '/meCoupon', auth: true, title: "我的优惠劵", component: () => import(/* webpackChunkName: 'MeCoupon' */'@/pages/Me/MeCoupon')},
        {path: '/meAgent', auth: true, title: "代理人", component: () => import(/* webpackChunkName: 'MeAgent' */'@/pages/Me/MeAgent')},
        {path: '/meMsg', auth: true, title: "消息通知", component: () => import(/* webpackChunkName: 'MeMsg' */'@/pages/Me/MeMsg')},
        {path: '/meXuBao', auth: true, title: "续保服务", component: () => import(/* webpackChunkName: 'MeXuBao' */'@/pages/Me/MeXuBao')},
        {path: '/meTuiBao', auth: true, title: "退保服务", component: () => import(/* webpackChunkName: 'MeTuiBao' */'@/pages/Me/MeTuiBao')},
        {path: '/meInviteFriends', auth: true, title: "邀请好友", component: () => import(/* webpackChunkName: 'MeInviteFriends' */'@/pages/Me/MeInviteFriends')},
        {path: '/meMore', auth: true, title: "更多", component: () => import(/* webpackChunkName: 'MeMore' */'@/pages/Me/MeMore')},
        {path: '/myCollection', auth: true, title: "我的收藏", component: () => import(/* webpackChunkName: 'MeShoucang' */'@/pages/Me/MeShoucang')},
        {path: '/myPeoples', exact: true, auth: true, title: "我的联系人", component: () => import(/* webpackChunkName: 'MePeoples' */'@/pages/Me/MePeoples')},
        {path: '/myPeoples/:id', exact: true, auth: true, title: "我的联系人", component: () => import(/* webpackChunkName: 'MePeopleInfo' */'@/pages/Me/MePeopleInfo')},
        {path: '/user', auth: true, title: "个人中心", component: () => import(/* webpackChunkName: 'MeUserInfo' */'@/pages/Me/MeUserInfo')},
        {path: '/myPhone', auth: true, title: "绑定手机", component: () => import(/* webpackChunkName: 'MePhone' */'@/pages/Me/MePhone')},
        {path: '/forgetPassword', title: "忘记密码", component: () => import(/* webpackChunkName: 'ForgetPassword' */'@/pages/Login/ForgetPassword')},
        {path: '/resetPassword1', title: "重置密码", component: () => import(/* webpackChunkName: 'ResetPassword' */'@/pages/Login/ResetPassword1')},
        {path: '/resetPassword', title: "重置密码", component: () => import(/* webpackChunkName: 'ResetPassword' */'@/pages/Login/ResetPassword')},
        {path: '/healthDeclaration', auth: true, title: "健康告知", component: () => import(/* webpackChunkName: 'HealthDeclaration' */'@/pages/Insure/HealthDeclaration')},
        {path: '/insure', auth: true, title: "投保信息填写", component: () => import(/* webpackChunkName: 'InsureForm' */'@/pages/Insure/InsureForm')},
        {path: '/bankCardSigning', auth: true, title: "在线签约", component: () => import(/* webpackChunkName: 'InsureBankCardSigning' */'@/pages/Insure/InsureBankCardSigning')},
        {path: '/404', title: "404",  component: () => import(/* webpackChunkName: 'ErrorPage' */'@/pages/OtherPage/ErrorPage')},
        {path: '/privacy-policy', title: "隐私政策",  component: () => import(/* webpackChunkName: 'PrivacyPolicy' */'@/pages/OtherPage/PrivacyPolicy')},
        {path: '/user-agreement', title: "用户协议",  component: () => import(/* webpackChunkName: 'UserAgreement' */'@/pages/OtherPage/UserAgreement')},
        {path: '/about-us', title: "关于我们",  component: () => import(/* webpackChunkName: 'AboutUs' */'@/pages/OtherPage/AboutUs')},
        {path: '/bindOpenId', title: "绑定微信",  component: () => import(/* webpackChunkName: 'bindOpenId' */'@/pages/Wechat/wechatBindOpenId')},

        //雇员管理页面
        {path: '/user-Employee', exact: true, auth: true,  title: "雇员管理",  component: () => import(/* webpackChunkName: 'UserAgreement' */'@/pages/Me/MeEmployee')},
        //雇员保单列表
        {path: '/user-Employee/detail', exact: true, auth: true,  title: "雇员保单",  component: () => import(/* webpackChunkName: 'UserAgreement' */'@/pages/Me/EmployeeDetail')},

        //开发票
        {path: '/MeInvoice', exact: true, auth: true,  title: "开发票",  component: () => import(/* webpackChunkName: 'UserAgreement' */'@/pages/Me/MeInvoice')},

        // 雇员常用联系人列表
        {path: '/peopleSen', exact: true, auth: true,  title: "常用联系人",  component: () => import(/* webpackChunkName: 'UserAgreement' */'@/pages/Me/MePeoplePerson')},


        // 惠泽产品回调页面 respCode respMsg
        {path: '/huize/result', auth: true, title: "惠泽认证结果页",  component: () => import(/* webpackChunkName: 'HuiZeResult' */'@/pages/OtherPage/HuiZeResult')},

        // 支付成功失败页
        {path: '/pay/result', title: "支付结果页",  component: () => import(/* webpackChunkName: 'Result' */'@/pages/OtherPage/PayResult')},



        // 下面是活动页
        {path: '/activity/g', exact: true, title: "保障在手出行无忧",  component: () => import(/* webpackChunkName: 'JuHeYe' */'@/pages/Activity/JuHeYe')},
        {path: '/activity/security', exact: true, title: "惠秦保",  component: () => import(/* webpackChunkName: 'HuiQinBao' */'@/pages/Activity/HuiQinBao')},
        {path: '/activity/g/rule', title: "活动规则",  component: () => import(/* webpackChunkName: 'JuHeYe' */'@/pages/Activity/JuHeYe/JuHeYeRule')},
        {path: '/activity/loveParent', exact: true, title: "感恩父母，关爱常在",  component: () => import(/* webpackChunkName: 'ShanZhen' */'@/pages/Activity/ShanZhen')},
        {path: '/activity/loveParent/form', title: "感恩父母，关爱常在",  component: () => import(/* webpackChunkName: 'ShanZhen' */'@/pages/Activity/ShanZhen/ShanzhenForm')},
        {path: '/activity/taiKangGaoYangTiQuWuNew', exact: true, title: "羔羊胃维B12医疗保险",  component: () => import(/* webpackChunkName: 'taiKangGaoYangTiQuWu' */'@/pages/Activity/TaiKangGaoYangTiQuWuNew')},
        {path: '/activity/taiKangGaoYangTiQuWu', exact: true, title: "羔羊胃维B12医疗保险",  component: () => import(/* webpackChunkName: 'taiKangGaoYangTiQuWu' */'@/pages/Activity/TaiKangGaoYangTiQuWu')},
        {path: '/result', title: "结果页",  component: () => import(/* webpackChunkName: 'Result' */'@/pages/OtherPage/Result')},
        {path: '/wechatPay', title: "在线支付",  component: () => import(/* webpackChunkName: 'WechatPay' */'@/pages/Pay/WechatPay')},

        // 测试使用
        {path: '/insureTest', title: "投保信息填写", component: () => import(/* webpackChunkName: 'InsureFormNotLogin' */'@/pages/Insure/InsureFormNotLogin')},
        {path: '/policyResult', title: "核保成功",  component: () => import(/* webpackChunkName: 'Test' */'@/pages/OtherPage/policyResult')},
        {path: '/test1', title: "核保成功",  component: () => import(/* webpackChunkName: 'Test1' */'@/pages/OtherPage/Test1')},

        {path: ['/activity/JiaZhenZongHeBaoXianJCK', '/activity/JiaZhenZongHeBaoXianSJK', '/activity/JiaZhenZongHeBaoXianGJK'], title: '关爱家政人员综合责任保险',component: ()=> import(/* webpackChunkName: 'JiazhengZongHeBaoXianBaoXianDetails' */'@/pages/Activity/JiazhengZongHeBaoXian/BaoXianDetails')},
        {path: '/activity/JiaZhenZongHeBaoXian/list', title: '关爱家政人员综合责任保险',component: ()=> import(/* webpackChunkName: 'JiazhengZongHeBaoXian' */'@/pages/Activity/JiazhengZongHeBaoXian')},
        {path: '/me/MeEnterprise', title:'企业信息',component: () => import(/* webpackChunkName: 'MeEnterprise' */'@/pages/Me/MeEnterprise')},
        {path: '/EmployeeList', title:'雇员信息',component: () => import(/* webpackChunkName: 'EmployeeList' */'@/pages/Me/EmployeeList')},
        {path: '/NewAddCorrect', title:'批改雇员',component: () => import(/* webpackChunkName: 'NewAddCorrect' */'@/pages/Me/NewAddCorrect')}
    ]

    render() {
        let {isLogin, isLoginOut} = this.props.globalStore;
        // const {location} = this.props;
        return (
                <Suspense fallback={<LazyLoading />}>
                    {/*<TransitionGroup>*/}
                    {/*    <CSSTransition key={location.key} timeout={1000} classNames={{*/}
                    {/*        enter: 'animated',*/}
                    {/*        enterActive: 'fadeInDown',*/}
                    {/*        exit: 'animated',*/}
                    {/*        exitActive: 'fadeOutDown'*/}
                    {/*    }}>*/}
                    <Switch>
                        <Redirect from='/' exact to="/home/index" />
                        <Redirect from='/home' exact to="/home/index" />
                        {
                            this.routes.map((route: TypeRoute) => (
                                <Route
                                    key={route.path.toString()}
                                    path={route.path}
                                    exact={route.exact}
                                    render={(props) => {
                                        const RedirectLoginComponent = () => {
                                            if (!isWechat() || isLoginOut) { // 不是微信环境或者手动点击了退出登录
                                                return (
                                                    <Redirect
                                                        to={{
                                                            pathname: "/login",
                                                            state: { from: props.location }
                                                        }}
                                                    />)
                                            }
                                            if (isWechat()) {
                                                wechatLogin(props.location)
                                            }
                                            return null;

                                        };
                                        const goLogin = route.auth && !isLogin;

                                        const children = route.children ? (
                                                <Switch>
                                                    {
                                                        route.children.map((routeChild: TypeRoute) => (
                                                            <Route
                                                                key={routeChild.path.toString()}
                                                                path={routeChild.path}
                                                                render={props => {
                                                                    const goLogin = routeChild.auth && !isLogin;
                                                                    const Component = lazy(routeChild.component)
                                                                    document.title = (routeChild.title) as string;
                                                                    return goLogin ? (<RedirectLoginComponent />) : ( <Component {...props}/>);
                                                                }}
                                                                exact={routeChild.exact}/>
                                                        ))
                                                    }
                                                    <Redirect to="/404" />
                                                </Switch>
                                        ) : [];
                                        if (route.children) {
                                            return goLogin ? (<RedirectLoginComponent />) : (<route.component {...props}  children={children} routes={route.children}/>);
                                        }else if(route.lazy === false){
                                            return goLogin ? (<RedirectLoginComponent />) : ( <route.component {...props}/>);
                                        } else { // 懒加载组件
                                            const Component = lazy(route.component)
                                            document.title = (route.title) as string;
                                            return goLogin ? (<RedirectLoginComponent />) : ( <Component {...props} children={children}/>);
                                        }
                                    }}
                                />
                            ))
                        }
                        <Redirect to="/404" />
                    </Switch>
                    {/*    </CSSTransition>*/}
                    {/*</TransitionGroup>*/}

                </Suspense>
        )
    }
}

export default MyRouter;
