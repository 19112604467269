import React, {Component} from 'react';
import {smsSend} from '@/api/user'
import cs from "classnames";
import {isPhone, isImgCode as IsImgCode} from '@/utils/checkParams';
import {Toast} from 'antd-mobile';
import { Button } from 'antd-mobile';
import { monitorLogData } from '@/api/recall';

type TypeProps = Api.sendSmsParams & {
    label: string;  // 默认显示的lable
    timeOut: number; // 再发发送延迟
    isImageCode: boolean, // 是否需要图片验证码
    disable: boolean, // 是否需要图片验证码
    loading: boolean, // 是否需要图片验证码
    className?: string; // 再发发送延迟
    buttonClick?(data?: any): void; // button 点击事件 没有的话默认发送验证码函数
    smsFun?(data?: any): Promise<any>; // button 点击事件 没有的话默认发送验证码函数
    successFun?(data?: any): void; // 成功后的一些逻辑
    errorFun?(e: any): void; // 报错后的一些逻辑
};

type TypeState = {
    changeLabel: string; // 更改后的label
    disable: boolean; // 按钮状态
}

class SmsCountDown extends Component<TypeProps, TypeState> {
    static defaultProps = {
        label: '获取验证码',
        timeOut: 60,
        isImageCode: false,
        disable: false,
        loading: false,
    };

    constructor(props: TypeProps) {
        super(props);
        this.state = {
            changeLabel: '', // button 改变的文字
            disable: false,
        }
    }

    isClick = false; // 放置多次点击

    buttonClick = () => {
        monitorLogData({
          eventCode: `buttonClick__${window.location.pathname}`,
          eventName: `${document.title}_获取验证码_点击`,
        });
        if (this.props.buttonClick) {
            this.props.buttonClick();
            return;
        } 
        if (this.isClick) return;
        if (!isPhone(this.props.mobile)) {
            Toast.fail('请输入正确的手机号码', 2);
            return;
        }
        const {successFun, errorFun, isImageCode, imageCode} = this.props;
        if (isImageCode && !IsImgCode(imageCode || '')) return Toast.fail('请输入正确的图片验证码', 2);

        this.isClick = true;
        this.sendSmsBegin();
        const smsFun = this.props.smsFun ?? this.smsFun
        smsFun()
            .then(res => {
                successFun?.(res);
                this.countDown();
            })
            .catch(e => {
                errorFun?.(e);
                this.countDownEnd();
            })
            .finally(() => {
                this.isClick = false;
            })
    };

    smsFun = () => {
        console.log('查看imageCode',this.props)
        const { mobile, type, imageCode} = this.props;
        return smsSend({
            imageCode: imageCode,
            mobile: mobile.replace(/\s/g, ''),
            type: type
        })
    }

    sendSmsBeginTimer: any = null;

    sendSmsBegin = () => {
        window.clearTimeout(this.sendSmsBeginTimer);
        let count = 1;
        this.sendSmsBeginTimer = window.setInterval(() => {
            if(this.isMount) {
                this.setState({
                    changeLabel: `正在发送${Array(count).fill('.').join('')}`
                });
                if(count === 3) {
                    count = 0;
                }
                count++
            }else {
                window.clearTimeout(this.sendSmsBeginTimer);
            }
        }, 800)

    };

    countDownTimer: any = null; // 计时器
    countDown = () => {
        let {timeOut = 60} = this.props;
        this.countDownBegin();
        this.countDownTimer = window.setInterval(() => {
            timeOut--;
            if(timeOut === 0) {
                return this.countDownEnd();
            }else if(this.isMount) {
                this.setState({
                    changeLabel: `${timeOut < 10 ? '0' + timeOut : timeOut} s`
                })
            }else {
                return this.countDownEnd();
            }
        }, 1000);
    };

    countDownBegin = () => {
        window.clearInterval(this.countDownTimer);
        window.clearInterval(this.sendSmsBeginTimer);
        this.setState({
            disable: true,
        });
    };

    countDownEnd = () => {
        window.clearInterval(this.countDownTimer);
        window.clearInterval(this.sendSmsBeginTimer);
        if(this.isMount) {
            this.setState({
                changeLabel: '重新发送',
                disable: false,
            })
        }
    };

    isMount = true;

    componentWillUnmount() {
        this.isMount = false;
    }


    render() {
        const {label, className, loading, disable: d} = this.props;
        const {changeLabel, disable} = this.state;
        return (
            <Button size='small' disabled={d || disable} loading={loading} className={cs(className)} onClick={this.buttonClick}>{changeLabel || label}</Button>
        );
    }
}

export default SmsCountDown;
