/**
 * 获取url参数
 * @param {string} name
 * @returns {*}
 */
import {isEmail, isIdCard, isPhone} from "@/utils/checkParams";
import {getProductShare, serviceProduct} from "@/api/product";
import {Wechat} from "@/utils/weChat";
import moment from "moment";
import {baseUrl, wechatAppid} from "@/constants";
import {getChannelInfo} from '@/channel'
import lodash from 'lodash';

export const getQueryString = <T = string> (name: string): T => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, "i");
    const r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return decodeURIComponent(r[2]) as unknown as T;
    }
    return '' as unknown as T;
};

export const parseQueryString = <T = any>(url = window.location.href): T =>  {
    var reg_url = /^[^?]+\?([\w\W]+)$/,
        reg_para = /([^&=]+)=([\w\W]*?)(&|$|#)/g,
        arr_url = reg_url.exec(url),
        ret: {[key: string]: any} = {};
    if (arr_url && arr_url[1]) {
        var str_para = arr_url[1], result;
        while ((result = reg_para.exec(str_para)) != null) {
            ret[result[1]] = decodeURIComponent(result[2]);
        }
    }
    return ret as T;
}

// 判断是否是微信环境
export const isWechat = () => !!navigator.userAgent.toLowerCase().match(/micromessenger/ig);


export const gPhone = (mobile: string = '') => {
    if (!mobile || !isPhone(mobile)) return mobile;
    return mobile.replace(/(\d{3})\d*(\d{4})/, '$1****$2')
}

export const gEmail = (email: string = '') => {
    if (!email || !isEmail(email)) return email;
    return email.replace(/(.{3}).*(@.*)/, '$1****$2')
}

export const gIdCard = (card: string = '') => {
    if (!card || !isIdCard(card)) return card;
    return card.replace(/(\d{6})\d*(\d{4})/, '$1********$2')
}

export const getWechatCode = (u = window.location.href, state: string = '') => {
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wechatAppid}&redirect_uri=${encodeURIComponent(u)}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
    window.location.replace(url);
};

export const getTerminal = () => isWechat() ? 'weChat' : 'H5';

/**
 * 把对象拼接成 key1=value1&key2=value2... form表单的形式
 * @param {object} params 参数对象
 * @param {boolean} encode 参数是否编码
 * @returns {string}
 */
export const joinParams = (params: {[key: string]: any}, encode: boolean = true) => {
    let result = '';
    if(!params) { return result; }
    for(const jsonStr in params) {
        if(typeof params[jsonStr] === 'object') {
                result += `${jsonStr }=${ encodeURIComponent(JSON.stringify(params[jsonStr])) }&`;
        }else {
            if (encode) {
                result += `${jsonStr }=${ encodeURIComponent(params[jsonStr]) }&`;
            }else  {
                result += `${jsonStr }=${ params[jsonStr] }&`;

            }
        }
    }
    return result.substring(0, result.length - 1);
};

export const weChatShare = (code: string, link = window.location.href) => {
    if (isWechat()) {
        getProductShare({
            ...getChannelInfo(),
            code,
        })
            .then(res => {
                new Wechat()
                    .wxShare({
                        title: res.name,
                        desc: res.memo,
                        imgUrl: res.image,
                        link,
                    })
            })
    }
}

export const wechatNotShare = () => new Wechat().wxNotShare();

export const weChatServiceShare = () => {
    if (isWechat()) {
        serviceProduct('1')
            .then(res => {
                new Wechat()
                    .wxShare({
                        title: res.name,
                        desc: res.memo,
                        imgUrl: res.image
                    })
            })
    }
}

export const setDate = (date: string, form: string = 'YYYY年MM月DD日') => {
    return moment(date).format(form)
}

export const wechatLogin = (state?: any) => {
    // 是微信环境 就微信登录咯
    // 第一步 存取当前location
    state && localStorage.setItem('state', JSON.stringify({from: state}));
    // 第二部设置微信回调域名
    const url = `${window.location.origin}/wechat`;
    // 第三步 去微信授权
    getWechatCode(url);
}

// 获取图片验证码  1登陆/注册 2：忘记密码 3:重置密码 4:绑定手机号 5:更换绑定手机号
export const getImageCodeImg = (mobile: string, type = 1) => `${baseUrl}/api/user/captcha/createImageCode?type=${type}&mobile=${mobile}&timestamp=${Date.now()}`

export const delay = (wait: number, args?: any) => new Promise((resolve, reject) => lodash.delay(resolve, wait, args))

export const realPx = (px: number) => Math.floor(px / 75 * parseFloat((window.document.documentElement.style as any)['font-size']))
