/*
 * @Description:
 * @Author: wangjin
 * @Date: 2021-02-04 22:24:42
 * @LastEditTime: 2021-02-06 17:30:23
 */
import React, {PureComponent} from 'react';
import cs from 'classnames';
import 'swiper/swiper-bundle.css';
import Swiper, {SwiperOptions} from 'swiper/bundle';
import _ from 'lodash'

type TypeSwiperSlideProps = {
    className?: string;
    dataId?: string;
    onClick?(): void;
}
export class SwiperSlide extends PureComponent<TypeSwiperSlideProps> {

    render() {
        return (
            <div data-id={this.props.dataId} className={cs('swiper-slide', this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}

type TypeMySwiperPorps = SwiperOptions & {
    className?: string;
    name: string;

}

class MySwiper extends PureComponent<TypeMySwiperPorps, {swiperParams: any, id: string}> {

    static globalSwiperList: {[key: string]: any} = {};

    static defaultProps: TypeMySwiperPorps = {
        name: `unName-${_.random(1000)}`, // Swiper的名字
    };

    constructor(props: TypeMySwiperPorps) {
        super(props);
        const {
            name, on, slidesPerView, spaceBetween, centeredSlides, pagination,
            loop
        } = this.props;
        const id = `#swiper-${name}`;
        let swiperParams = {...MySwiper.defaultProps}
        on && (swiperParams.on = on);
        slidesPerView && (swiperParams.slidesPerView = slidesPerView);
        spaceBetween && (swiperParams.spaceBetween = spaceBetween);
        centeredSlides && (swiperParams.centeredSlides = centeredSlides);
        loop && (swiperParams.loop = loop);
        pagination && (swiperParams.pagination = {
            el: `.swiper-pagination`
        });
        this.state = {
            swiperParams,
            id
        }
    }

    swiper: Swiper | null = null; // 当前实例

    componentDidMount() {
        this.initSwiper();
    }


    componentDidUpdate(prevProps: Readonly<TypeMySwiperPorps>, prevState: Readonly<{}>, snapshot?: any) {
        this.initSwiper();
    }

    initSwiper() {
        if (this.swiper) {
            this?.swiper?.destroy?.();
            MySwiper.globalSwiperList[this.props.name] = null;
        }
        const {id, swiperParams} = this.state;
        const {name} = this.props;
        this.swiper = new Swiper(id, swiperParams);
        if (MySwiper.globalSwiperList[name]) {
            console.warn('您的项目中swiper实例有相同的实例')
        } else {
            MySwiper.globalSwiperList[name] = this.swiper;
        }
    }

    componentWillUnmount() {
        this?.swiper?.destroy?.();
        MySwiper.globalSwiperList[this.props.name] = null;
    }

    render() {
        const {className, name, children, pagination, scrollbar, navigation} = this.props;
        return (
            <div className={cs('swiper-container', className)} id={`swiper-${name}`}>
                <div className="swiper-wrapper">
                    {children}
                </div>
                {
                    ///*如果需要分页器*/
                    pagination && (<div className="swiper-pagination" />)
                }
                {
                    //如果需要导航按钮
                    navigation && (
                        <>
                            <div className="swiper-button-prev"/>
                            <div className="swiper-button-next"/>
                        </>
                    )
                }
                {
                    // 如果需要滚动条
                    scrollbar && (<div className="swiper-scrollbar"/>)
                }
            </div>
        );
    }
}

export default MySwiper;
