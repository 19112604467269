import axios from "axios";
import store from '@/store';
import history from "@/utils/history";
// import {refreshToken} from "@/api/user";

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if(store.isLogin) {
        config.headers.AccessToken = store.accessToken;
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
// let count = 1;
axios.interceptors.response.use(async function (response) {
    const { data } = response;
    if ((response.config as any).skipErr) {
        return Promise.resolve(data)
      }
    if(response.status >= 200 && response.status < 400) {
        if (data.success) {
            return data.data;
        } else {
            if (data.code === 9000107 || data.code === 1000105 || data.code === 9000108) { // 过期token 重新登录
                store.loginOut();
                history.push('/login', {from: history.location})
                // if (count === 2) {
                //     count = 1;
                //     return Promise.reject(data);
                // }
                // console.log('过期了，要去刷新token');
                // const {config} = response;
                // return await refreshTokenFun(config);
                return Promise.reject({msg: '未登录或已过期，请重新登录'})
            }
            return Promise.reject({...data, msg: data.msg ?? '未知错误，请稍后重试'});
        }
    } else {
        return Promise.reject({
            code: response?.status,
            msg: response?.statusText,
            response
        });
    }
}, function (error) {
    // Do something with response error
    return Promise.reject(error?.response?.data || {
        code: error.response?.status,
        msg: error.response?.statusText,
        error
    });
});

// function refreshTokenFun (config: AxiosRequestConfig) {
//     return refreshToken(store.userInfo?.refreshToken)
//         .then(data => store.login(data))
//         .then(() => {
//             const reload: AxiosRequestConfig = {
//                 url: config.url,
//                 headers: {
//                     ...config.headers,
//                     AccessToken: store.accessToken
//                 },
//                 method: config.method,
//             }
//             if (reload.method === 'get') {
//                 reload.params = {
//                     ...config.params,
//                 }
//             } else if (reload.method === 'post') {
//                 reload.data = {
//                     ...config.data,
//                 }
//             }
//             count++;
//             return axios(reload)
//         })
// }

export default axios;
