/*
 * @Description:
 * @Author: wangjin
 * @Date: 2021-02-20 17:01:32
 * @LastEditTime: 2021-02-24 00:08:48
 */
import axios from "@/utils/request";
import {baseUrl as bUrl} from '@/constants';
const baseUrl = `${bUrl}/api/user`;
const oederUrl = `${bUrl}/api/order`;
const flowUrl = `${bUrl}/api/flow`;
//getQrcode  memberChange
const memberUrl = `${bUrl}/api/user/member`
// 登录
export const login = (data: Api.loginParams) => axios.post<any, Api.loginResponse>(`${memberUrl}/login`, data)


//90天判断
export const checkDate = () => axios.post<any, Api.loginResponse>(`${memberUrl}/resetPassportDate`)

// 刷新token
export const refreshToken = (refreshToken?: string) => axios.get<any, Api.loginResponse>(`${memberUrl}/refreshToken`, {params:{refreshToken}})


// 绑定手机号
    export const bindingMobile = (data: {mobile: string, smsCode: string}) => axios.post(`${memberUrl}/bindingMobile`, data)

// 绑定手机号
export const changeBindingMobile = (data: {checkToken: string, mobile: string, smsCode: string}) => axios.post(`${memberUrl}/changeBindingMobile`, data)

// 验证密码
export const checkPassword = (data: {refreshToken: string, password: string}) => axios.post(`${memberUrl}/checkPassword`, data)


// 忘记密码
export const forgetPassword = (data: {checkToken?: string, confirmPassword: string, newPassword: string, mobile?: string, password?: string, type: string}) => axios.post(`${memberUrl}/forgetPassword`, data)

// 判断是否注册
export const isRegister = (mobile: string) => axios.get<boolean>(`${memberUrl}/isRegister`, {params: {mobile}})


// 退出登录
export const loginOut = (refreshToken: string) => {
    const formData = new FormData();
    formData.append('refreshToken', refreshToken)
    return axios.post(`${memberUrl}/loginOut`, formData)
}

// 获取用户详情
export const memberDetail = (config: any = {}) => axios.get<any, User.UserInfo>(`${memberUrl}/memberDetail`, config)

// 重置密码  type 1:旧密码重置密码 2:短信验证码重置密码
export const resetPassword = (data: {checkToken?: string, confirmPassword: string, newPassword: string, mobile?: string, password?: string, type: string}) => axios.post(`${memberUrl}/resetPassword`, data)

// 设置密码
export const setUpPassword = (data: {confirmPassword: string, newPassword: string}) => axios.post(`${memberUrl}/setUpPassword`, data)

//申请机构
export const saveEnterprise = (data: any) => axios.post(`${baseUrl}/memberOrganization/applyMemberOrganization`, data)

/**
 *获取是否需要获取图片验证码
 * @param data.type 1：登陆/注册 2：忘记密码 3:重置密码 4:绑定手机号 5:更换绑定手机号
 * @returns Promise<{isImageCode: 是否需要获取图片验证码, mobile: 手机号}>
 */
export const smsIsImageCode = (data: {imageCode?: string, mobile: string, type: string}) => axios.post<any, {isImageCode: boolean, mobile: string}>(`${baseUrl}/sms/isImageCode`, data)

// 短信发送
export const smsSend = (data: Api.sendSmsParams) => axios.post<{mobile: string}>(`${baseUrl}/sms/send`, data)

// 校验短信验证码
export const smsCheckCode = (data: {code: string, mobile: string, type: string}) => axios.post<any, {checkToken: string}>(`${baseUrl}/sms/checkCode`, data)


// 根据Id 删除常用联系人
export const contactPersonDelete = (id: string) => {
    const data = new FormData();
    data.append('id', id)
    return axios.post(`${baseUrl}/contactPerson/delete`, data)
}

/**
 * 查询常用联系人
 * @param data.current 页数
 * @param data.isDelete 是否删除 是:true 否:false
 * @param data.memberId 会员Id
 * @param data.size 条数
 */
export const contactPersonGetList = (data: {current?: number, isDelete?: boolean, memberId?: number, size?: number}) => axios.post<any, Api.RowList<User.UserInfo>>(`${baseUrl}/contactPerson/getList`, data)

// 根据Id 获取常用联系人信息
export const contactPersonGetOne = (id: string) => axios.get<any, User.UserInfo>(`${baseUrl}/contactPerson/getOne`, {params: {id}})


// 添加常用联系人信息
export const contactPersonSaveOne = (data: User.UserInfo) => axios.post(`${baseUrl}/contactPerson/saveOne`, data)

// 获取收藏列表
export const collectGetList = (data: {current: number, memberId?: number, size: number, type: '1' | '2' | '3'}) => axios.post<any, Api.RowList<Api.collectItem>>(`${baseUrl}/collect/getList`, data)


// 修改常用联系人信息
export const contactPersonUpdateOne = (data: User.UserInfo) => axios.post(`${baseUrl}/contactPerson/updateOne`, data)

// 收藏 类型 1:保险产品 2:文章 3:活动
export const collectSaveOne = (data: {memberId?: string, objId: string, type: string}) => axios.post(`${baseUrl}/collect/saveOne`, data)

// 绑定openid
export const bindingOpenId = (code: string) => {
    const formData = new FormData();
    formData.append('code', code);
    return axios.post(`${baseUrl}/weChat/binding/openId`, formData);
}

// 获取url签名
export const getSignature = (url: string) => {
    const formData = new FormData();
    formData.append('url', url);
    return axios.post<any, Api.wechatSignature>(`${baseUrl}/weChat/js/getSignature`, formData)
}

// 微信登录
export const wechatLogin = (code: string) => {
    const formData = new FormData();
    formData.append('code', code);
    return axios.post<any, Api.loginResponse>(`${baseUrl}/weChat/login`, formData)
}

// /api/user/getQrcode

export const getQrcode = (data: {
    color?: string;
    needLogo?: boolean;
    size?: number;
    url?: string;
}) => axios.get<any, any>(`${memberUrl}/getQrcode`, {params: data, responseType: 'blob', skipErr: true,}  as any)

export const getMemberOrganizationInfo = () => axios.get<any, any>(`${baseUrl}/memberOrganization/getMemberOrganizationInfo`)

// 雇员列表
export const employeetGetList = (data: {name?: string,current?: number, memberId?: number, size?: number}) => axios.post<any, Api.RowList<User.UserInfo>>(`${oederUrl}/policyEmployee/getListByMemberId`, data)

// 雇员保单详情
export const employeetPolicyGetList = (data: {current?: number, isDelete?: boolean, memberId?: number, size?: number}) => axios.post<any, Api.RowList<User.UserInfo>>(`${baseUrl}/contactPerson/getList`, data)

//待开票
export const invoiceList = (data: {current?: number, memberId?: number, invoiceStatus?:number, size?: number}) => axios.post<any, Api.RowList<User.UserInfo>>(`${oederUrl}/InvoicePolicy/getNotIssueInvoiceList`, data)
// 已开票
export const alreadyInvoiceList = (data: {current?: number, memberId?: number, invoiceStatus?:number, size?: number}) => axios.post<any, Api.RowList<User.UserInfo>>(`${oederUrl}/InvoiceApply/getInvoiceApplyList`, data)

// 开具发票
export const invoicePost = (data: { policyNoList: any[],selectAllStatus:number }) => axios.post<any, Api.RowList<User.UserInfo>>(`${flowUrl}/picc/createInvoiceByPolicyNoList`, data)

//获取金额
export const invoiceAmount = (data: { t:number }) => axios.post<any, Api.RowList<User.UserInfo>>(`${oederUrl}/InvoicePolicy/selectAllInvoiceAmount`, data)

//重开发票/picc/reopenInvoice
export const onceOpenInvoice = (data: { invoiceApplyNo:string }) => axios.post<any, Api.RowList<User.UserInfo>>(`${flowUrl}/picc/reopenInvoice`, data)

