import IdCard from 'id-validator';
import verifyRegExp from "@/constants/verifyRegExp";
import moment from 'moment';

export const IdCardValidator = new IdCard();

/**
 * 解析身份证信息
 * @param {string} idCardStr
 * @returns {{addrCode: string, birth: string, checkBit: string, length: 18 | 15, sex: 1 | 0} | false}
 */
export const getIdCardInfo = (idCardStr: string) => IdCardValidator.getInfo(idCardStr);

/**
 * 解析身份证是否合法
 * @param {string} idCardStr
 * @returns {boolean}
 */
export const isIdCard = (idCardStr: string): boolean => IdCardValidator.isValid(idCardStr);

/**
 * 检验手机号时候合法
 * @param phone
 * @returns {boolean}
 */
export const isPhone = (phone: string) => {
    const p = phone.replace(/\s/g, '')
    return verifyRegExp.phone.test(p)
};

/**
 * 检验手机号验证码是否正确
 * @param code
 * @returns {boolean}
 */
export const isPhoneCode = (code: string) => verifyRegExp.phoneCode.test(code);

/**
 * 检验密码是否正确
 * @param code
 * @returns {boolean}
 */
export const isPassword = (code: string) => verifyRegExp.password.test(code);
/**
 * 检验图片验证码是否正确
 * @param code
 * @returns {boolean}
 */
export const isImgCode = (code: string) => verifyRegExp.imageCode.test(code);


/**
 * 校验邮箱是否合法
 * @param email
 * @returns {boolean}
 */
export const isEmail = (email: string) => verifyRegExp.email.test(email);

/**
 * 校验是否是中国名字
 * @param name
 * @returns {boolean}
 */
export const isChinaName = (name: string) => verifyRegExp.chinaName.test(name);

/**
 * 校验是否是银行卡号
 * @param cardNo
 * @returns {boolean}
 */
export const isBankCardNo = (cardNo: string) => verifyRegExp.bankCardNo.test(cardNo);

/**
 * 获取年龄
 * @param {string | Date | Moment} target 目标年龄
 * @param {string | Date | Moment} start 计算年龄的基数
 * @returns {null | boolean}
 */
export const getAge = (target: string | Date | moment.Moment, start: string | Date | moment.Moment = moment()) => (moment(start).isValid() ? isIdCard(target as string) ? moment(start).diff((getIdCardInfo(target as string) as any).birth, 'year') : moment(target).isValid() ? moment(start).diff(moment(target), 'year') : null : null);

/**
 * 校验是否有值
 * @param ins
 * @returns {boolean}
 */
export const isNull = (ins: any) => (ins === '' || ins === undefined || ins === null);

// /**
//  * 校验对象是否有合法值
//  * @param obj
//  * @returns {boolean | string|boolean}
//  */
// export const checkObj = (obj: any) => {
//     if (isNull(obj) || !obj instanceof Object) return false;
//     return Object.keys(obj).find(key => {
//         if (isNull(obj[key])) {
//             return true;
//         }
//         switch (key) {
//             case 'name':    // 姓名暂不做校验
//                 return false;
//             case 'idCardNo':
//                 return !isIdCard(obj[key]);
//             case 'mobile':
//                 return !isPhone(obj[key]);
//             case 'email':
//                 return !isEmail(obj[key]);
//             default:
//                 return false;
//         }
//     })
// };

/**
 * 判断年龄是否在两个年龄区间
 * @param {IdCard | string | moment | Date}target
 * @param {number} maxAge 最大年龄
 * @param {number} minAge 最小年龄
 * @returns {boolean}
 */
export const isBetweenDate = (target: Date | string | moment.Moment, maxAge: number, minAge: number) => isIdCard(target as string) ? moment((getIdCardInfo(target as string) as any).birth).isBetween(moment().subtract(maxAge, 'year').startOf('day').subtract(1, 'day'), moment().subtract(minAge, 'year').startOf('day').add(1, 'day')) : moment(target).isValid() ? moment(target).isBetween(moment().subtract(maxAge, 'year').startOf('day'), moment().subtract(minAge, 'year').startOf('day')): null;

// // 递归，判断空值
// export const checkoutNull = (obj) => {
//     return Object.keys(obj).every(key => {
//         if (obj[key] instanceof Object) {
//             return checkoutNull(obj[key])
//         }
//         return obj[key] !== null && obj[key] !== '' && obj[key] !== undefined;
//     })
// };

/**
 * 判断身份证是否符合年龄范围 格式  30-d 代表30天  30-y 代表 30年
 * @param idCard
 * @param minAge
 * @param maxAge
 */
export const isIdCardBetweenAge = (idCard: string, minAge: string, maxAge: string) => {
    // 检测年龄
    const {birth} = getIdCardInfo(idCard) || {};
    if (birth && minAge && maxAge) {
        const min = minAge.split('-');
        const max = maxAge.split('-');
        return moment(birth).isBetween(moment().startOf('d').subtract(max[0], max[1] as any), moment().startOf('d').subtract(min[0], min[1] as any))
    }
    return false
}
