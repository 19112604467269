import React, {Component} from 'react';
import {Icon, Flex} from 'antd-mobile';
import style from './Back.module.less';
import cs from "classnames";
import { monitorLogData } from '@/api/recall';

type TypeProps = {
    title: string; // 名称
    styleType?: 'fangkuai'; // 名称
    fixed: boolean;
    className?: string; // 名称
    step: number,   // 后退几步 默认 -1
    history: any;
    back?: boolean; // 是否显示返回按钮 默认 true
    backFun?(): void; // 是否有点击返回的函数，有的话会执行
}

class Back extends Component<TypeProps> {
    static defaultProps = {
        title: '关爱保险经纪',
        back: true,
        fixed: false,
        step: -1
    }
    back = () => {
        monitorLogData({
            eventCode: `back__${window.location.pathname}`,
            eventName: "返回",
          });
        this.props.history.go(this.props.step);
    }
    render() {
        const {back, title, className, fixed, styleType} = this.props;
        return (
            <div className={styleType ? style[styleType] : style.backBox}>
                <div className={cs(style.back, styleType ? style[styleType] : '', className ? className : style.backBg, cs({[style['back-fixed']]: fixed}))}>
                    <Flex align='center' onClick={this.back}>
                        {back && <Icon type='left'/>}
                        <span>{title}</span>
                    </Flex>
                </div>
            </div>
        );
    }
}

export default Back;
