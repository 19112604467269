import {Component} from 'react';
import {bindingOpenId, wechatLogin} from '@/api/user';
import {getQueryString, isWechat} from "@/utils";
import {inject, observer} from 'mobx-react';
import type {RouteComponentProps} from 'react-router-dom';
import {Toast} from "antd-mobile";

@inject('globalStore')
@observer
class WechatCode extends Component<RouteComponentProps & {globalStore: Page.globalStore}> {

    pageInfo = {
        code: getQueryString('code'),
        bindOpenId: getQueryString('bindOpenId'),
        location: JSON.parse(localStorage.getItem('state') || '{}')?.from ?? {}
    }

    componentDidMount() {
        if (!isWechat()) return this.props.history.replace('/');
        this.init().then(this.loading.done)
    }

    async init() {
        if (this.pageInfo.bindOpenId === 'bindOpenId') {
            await this.bindingOpenId();
            return true;
        }
        await this.wechatLogin();
        return true;
    }

    bindingOpenId() {
        return bindingOpenId(this.pageInfo.code)
            .then(res => {
                const pathname = this.pageInfo.location?.pathname || '/';
                const search = this.pageInfo.location?.search || '';
                const state = this.pageInfo.location?.state || {};
                this.props.history.replace(`${pathname}${search}`, state);
            })
            .catch(e => Toast.fail(e.msg))
    }

    wechatLogin() {
        return wechatLogin(this.pageInfo.code)
            .then(userInfo => {
                this.props.globalStore.login(userInfo)
                const pathname = this.pageInfo.location?.pathname || '/';
                const search = this.pageInfo.location?.search || '';
                const state = this.pageInfo.location?.state || {};
                this.props.history.replace(`${pathname}${search}`, state);
            })
            .catch(e => {
                Toast.fail(e.msg)
                this.props.history.replace('/login', {from: this.pageInfo.location})
            })
    }

    render() {
        return null;
    }
}

export default WechatCode;
