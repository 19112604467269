import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'mobx-react';
import globalStore from '@/store';




// 初始化css
import './global.less'
// js 兼容垫片
import 'babel-polyfill';
// 淘宝 rem 字体控制
import 'lib-flexible/flexible.js';

import MyRouter from './routers/index';

// 添加页面loading
import NProgress from 'nprogress';
React.Component.prototype.loading = NProgress;
NProgress.start();
ReactDOM.render(
    <Provider globalStore={globalStore}>
        <BrowserRouter>
            <MyRouter />
        </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

if (process.env.REACT_APP_ENV === 'uat') {
    import ('vconsole')
        .then(({ default: vConsole }) => new vConsole({}));
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
