const verifyRegExp =  {
    phone: /^[1][3456789][0-9]{9}$/,
    imageCode: /^[a-zA-z0-9]{4}$/,
    password: /^[a-zA-z0-9]{8,16}$/,
    phoneCode: /^\d{6}$/,
    repairDesciption: /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/,
    regIsOrderId: /^(\w{8}|\w{10}|\w{11}|\w{17})$/,
    IDCard: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/,
    email: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
    chinaName: /^[\u4E00-\u9FA5]{2,6}(?:·[\u4E00-\u9FA5]{2,6})*$/,
    pdfName: /^\/.*\/(.*).pdf/,
    bankCardNo: /^([1-9]{1})(\d{15}|\d{18})$/
};

export default verifyRegExp;
