import {defaultChannelInfo} from "@/constants";
import { getQueryString } from "@/utils";
import Cookie from 'js-cookie';

export interface TypeChannelInfo  {
    channelId: string;
    terminal: string;
}

// 获取渠道信息
export const getChannelInfo = (): TypeChannelInfo => {
    const channelId = getQueryString('channelId');
    const terminal = getQueryString('terminal');
    if (channelId) {
        // 设置 cookid
        Cookie.set('channelId', channelId, {expires: 1})
    }
    if (terminal) {
        // 设置 cookid
        Cookie.set('terminal', terminal, {expires: 1})
    }
    return {
        channelId: Cookie.get('channelId') || defaultChannelInfo.channelId,
        terminal: Cookie.get('terminal') || defaultChannelInfo.terminal
    };
}
