// 视频回溯系统接口
import axios from "@/utils/request";
import { baseUrl as bUrl } from "@/constants";
import { rrwebData } from "@/utils/rrew";
const baseUrl = `${bUrl}/api/backtrack`;

export const recallInsertBatch = (data: rrwebData[]) => axios.post(`${baseUrl}/recall/insertBatch`, data)

export const recallGetData = () => axios.get<any, any>(`${baseUrl}/recall/getData`)

export const monitorLogData = (data: { eventCode: string, eventName: string }) => axios.post(`${baseUrl}/monitorLog/save`, data, { skipErr: true } as any);


