import React, { PureComponent } from 'react';
import { Flex } from 'antd-mobile';
import {NavLink} from 'react-router-dom';
import style from './HomeNav.module.less';
import indexActiveImg from './images/index_selected.png';
import indexImg from './images/index.png';
import insuranceImg from './images/insurance.png';
import insuranceActiveImg from './images/insurance_selected.png';
import serviceImg from './images/service.png';
import serviceActiveImg from './images/service_selected.png';
import meImg from './images/me.png';
import meActiveImg from './images/me_selected.png';
import type {TypeRoute} from '@/routers'
import {getChannelInfo} from "@/channel";
import {defaultChannelInfo} from "@/constants";
type TypeProps =  {
    routes: TypeRoute[];
}

class HomeNav extends PureComponent<TypeProps,TypeProps> {

    constructor(props: TypeProps) {
        super(props);
        const {routes} = this.props;
        this.state = {
            // @ts-ignore
            routes: routes.map(v => ({...v, path: HomeNav.getRoutePath()[v.name] ?? v.path}))
        }
    }
    private static getRoutePath = () => {
        const {channelId} = getChannelInfo();
        if (channelId === defaultChannelInfo.channelId) {
            // 关爱保险主站
            return {
                service: 'https://baidu.com?1'
            }
        } else {
            return {
                index: `/home/index?channelId=${defaultChannelInfo.channelId}&terminal=${defaultChannelInfo.terminal}`,
                insurance: `/home/insurance?channelId=${defaultChannelInfo.channelId}&terminal=${defaultChannelInfo.terminal}`,
                me: `/home/me?channelId=${defaultChannelInfo.channelId}&terminal=${defaultChannelInfo.terminal}`,
            }
        }
    }


    private static getIcon(key: string): [string, string] | undefined {
        switch (key) {
            case 'index':
                return [indexActiveImg, indexImg]
            case 'insurance':
                return [insuranceActiveImg, insuranceImg]
            case 'service':
                return [serviceActiveImg, serviceImg]
            case 'me':
                return [meActiveImg, meImg]
        }
    }

    render() {
        const {routes} = this.state;
        return (
            <div className={style.nav}>
                <Flex className={style.flexBox}>
                {
                    routes.map(route => (
                        <Flex.Item key={route.path} className={style.navItem}>
                            {
                                route.path.toString().startsWith('http')? (
                                    <a className="nav-a" href={route.path.toString()}>
                                        <Flex direction="column">
                                            <div className={style.imgContainer}>
                                                {
                                                    HomeNav.getIcon(route.name as string)?.map((icon, index) => (
                                                        <img key={index} className={index === 0 ? 'activeImg' : ''} src={icon} alt={route.navName}/>
                                                    ))
                                                }

                                            </div>
                                            <Flex.Item className={style.navText}>{route.navName}</Flex.Item>
                                        </Flex>
                                    </a>
                                ) : (
                                    <NavLink to={route.path} activeClassName="active">
                                        <Flex direction="column">
                                            <div className={style.imgContainer}>
                                                {
                                                    HomeNav.getIcon(route.name as string)?.map((icon, index) => (
                                                        <img key={index} className={index === 0 ? 'activeImg' : ''} src={icon} alt={route.navName}/>
                                                    ))
                                                }

                                            </div>
                                            <Flex.Item className={style.navText}>{route.navName}</Flex.Item>
                                        </Flex>
                                    </NavLink>
                                )
                            }
                        </Flex.Item>
                    ))
                }
                </Flex>
            </div>
        );
    }
}

export default HomeNav;
