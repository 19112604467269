import {isWechat} from './index';
import { getSignature } from "@/api/user";
import wx from 'weixin-js-sdk';
/**
 * 这是微信 sdk 的配置文件
 * 关于分享：由于分享
 */
export class Wechat {
    static shareList = [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'hideMenuItems', // 批量隐藏功能按钮接口 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
        'hideAllNonBaseMenuItem', // 隐藏所有非基础按钮
    ];
    /**
     *
     * @param url
     * @returns {*}
     */
    /**
     * 微信配置
     * @param {string} url href
     * @returns {void} 无
     */
    wechatConfig(url = window.location.href) {
        if (isWechat()) {
            return new Promise(async(resolve, reject) => {
                try {
                    const wechatInfo = await getSignature(url);
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，
                        // 若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: wechatInfo.appId, // 必填，公众号的唯一标识
                        timestamp: wechatInfo.timestamp, // 必填，生成签名的时间戳
                        nonceStr: wechatInfo.nonceStr, // 必填，生成签名的随机串
                        signature: wechatInfo.signature, // 必填，签名，见附录1
                        // @ts-ignore
                        jsApiList: Wechat.shareList // 必填，需要使用的JS接口列表
                    });
                    wx.ready(function() {
                        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，
                        // 所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
                        // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                        // 默认隐藏所有的基础按钮
                        // wx.hideAllNonBaseMenuItem();
                        resolve(wx);
                    });
                    wx.error(function(res) {
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，
                        // 具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                        // that.msg("服务器出错，部分微信分享功能将不可用，如需分享，请刷新重试")
                        reject({
                            code: -1,
                            msg: res.errMsg
                        });
                    });
                } catch (e) {
                    reject({
                        code: -1,
                        msg: 'url 签名失败',
                    });
                }

            });

        } else {
            return Promise.reject({
                code: -1,
                msg: '当前不是微信环境'
            });
        }
    };

    /**
     * @param {any} args 分享参数
     * @param{string} args.title 分享标题
     * @param{string} args.link 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
     * @param{string} args.imgUrl 焚香图标
     * @param{string} args.desc 分享描述
     * @param{string} args.type 分享类型 music、video或link，不填默认为link
     * @param{string} args.dataUal 如果type是music或video，则要提供数据链接，默认为空
     * @returns {Promise<boolean>} 配置成功或者失败
     * title: '', link: '', imgUrl: '', desc: '', type: '', dataUal: ''
     */
    wxShare(args: {title?: string, link?:string, imgUrl?: string, desc?: string} = {}) {

        let {
            title = '',
            link = window.location.href,
            imgUrl = '',
            desc = ''
        } = args;
        console.log(link)
        // 一些修改(删掉连接上的非法code)；
        link = link.replace(/(^|&)?code=([^&]*)/g, '');
        return this.wechatConfig()
            .then(res => {
                // @ts-ignore
                wx.updateAppMessageShareData({
                    title,
                    link,
                    imgUrl,
                    // @ts-ignore
                    desc
                });
                // @ts-ignore
                wx.updateTimelineShareData({
                    title,
                    link,
                    imgUrl,
                    desc
                });
                return Promise.resolve(res);
            })
            .catch(e => Promise.reject(e));

    };

    wxNotShare() {
        this.wechatConfig()
            .then(res => {
                wx.hideAllNonBaseMenuItem();
                return Promise.resolve(res);
            })
            .catch(e => Promise.reject(e));
    };
}
