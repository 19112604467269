import axios from "@/utils/request";
import {baseUrl as bUrl} from '@/constants';
import { getChannelInfo } from "@/channel";
import {TypeTemplate} from "@/pages/Insure/InsureForm";
const baseUrl = `${bUrl}/api/product`;

// 查询首页产品列表
export const getProductListByHomeMenuId = (id: number) => axios.get<any, Api.ProductItem[]>(`${baseUrl}/homeMenu/getProductListByHomeMenuId`, {params: {id}})

// 查询产品页类别列表
export const getProductListByMenuId = (id?: string) => axios.get<any, Api.ProductItem[]>(`${baseUrl}/menu/getProductListByMenuId`, {params: {id, ...getChannelInfo()}})

// 状态 0:下架状态；1:上线；2敬请期待
export const getProductDetailByIdAndChannel = (data: {channelId: string, code: string, terminal: string}) => axios.post<any, Page.ProductDetail>(`${baseUrl}/product/getProductDetailByIdAndChannel`, data)


// 查询服务类产品信息
export const getServiceProductList = (data: {current: number, size: number}) => axios.post<any, Api.RowList<Api.ProductItem>>(`${baseUrl}/serviceProduct/getList`, data)

// 查询服务产品信息
export const serviceProduct = (id: string) => axios.get<any, Api.ProductItem>(`${baseUrl}/serviceProduct/getProductById`, {params: {id}})


// 产品分享接口
export const getProductShare = (data: {channelId: string, code: string, terminal: string}) => axios.post<any, {code: string, id: string, image: string,memo: string, name: string, price: string, priceType: string}>(`${baseUrl}/product/getProductShare`, data)


// 查询健康告知类型
export const getHealthToldType = (params: {productCode: string, channelId: string, terminal: string}) => axios.get<any, {answerType: 'all' | 'answer', id: string}[]>(`${baseUrl}/healthtold/getHealthToldType`, {params})

// 查询健康告知内容
export const getHealthToldContent = (healthToldId: string) => axios.get<any, Api.HealThDeclarationItem[]>(`${baseUrl}/healthtold/getHealthToldItem`, {params: {healthToldId}})
export const getDicItemInfoById = (params: {id: string, value?: string}) => axios.get<any, { key: string, value: string, parent: string }[]>(`${baseUrl}/insureTemplate/getDicItemInfoById`, {params})


export const insureTemplateInfo = (id: string) => axios.get<any, { templateJson: TypeTemplate}>(`${baseUrl}/insureTemplate/getInfoById`, {params: {id}})

