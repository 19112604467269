import React, {Component} from 'react';
import {Flex} from 'antd-mobile';
import notDataImg from './images/投保失败.png';

class NotData extends Component {

    render() {
        return (
            <Flex className="notData" style={{width: '100%', height: '100%'}} direction='column' align="center">
                <img src={notDataImg} alt=""/>
                <span>暂无数据</span>
            </Flex>
        );
    }
}

export default NotData;
