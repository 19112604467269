import React from "react";
import { Link } from "react-router-dom";
import { Toast } from "antd-mobile";
import { monitorLogData } from "@/api/recall";

// 参数同 Link 的参数
const MyLink: React.FC<{
  to: string | any;
  className?: string;
  isExpect?: boolean;
  replace?: boolean;
  onClick?(e: MouseEvent | any): void;
}> = ({ isExpect, ...props }) => {
  const toastExpect = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    Toast.show("敬请期待", 1);
    return false;
  };
  const replaceurl = () => {
    if (props.to) {
      monitorLogData({
        eventCode: props.to,
        eventName: "外部链接跳转",
      });
      if (props.onClick) {
        props?.onClick?.(props.to);
      } else {
        window.location.replace(props.to);
      }
    }
  };

  const hrefClick = (e: any) => {
    if (props.to) {
      monitorLogData({
        eventCode: props.to,
        eventName: "外部链接跳转",
      });
      props.onClick?.(e);
    }
  };

  // 敬请期待
  if (isExpect) {
    return (
      <a
        className={props.className}
        href={props.to}
        onClick={props.onClick ?? toastExpect}
      >
        {props.children}
      </a>
    );
  }

  if (props?.to?.startsWith("http") || props?.to?.startsWith("tel")) {
    return props.replace ? (
      <span className={props.className} onClick={replaceurl}>
        {props.children}
      </span>
    ) : (
      <a className={props.className} href={props.to} onClick={hrefClick}>
        {props.children}
      </a>
    );
  }

  return (
    <Link {...props} onClick={props.onClick}>
      {props.children}
    </Link>
  );
};

export default MyLink;
