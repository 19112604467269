import { action, observable, computed } from 'mobx';
// public 公开 private 私有 protected 保护
class GlobalStore implements Page.globalStore {

    @observable
    userInfo: Api.loginResponse | null = (JSON.parse(localStorage.getItem('userInfo') as any));

    @observable
    currentUser: User.UserInfo | null = null;

    // 是否手动点击退出登录的
    @observable
    isLoginOut: boolean = false;

    @action.bound
    setIsLoginOut(isLoginOut: boolean) {
        this.isLoginOut = isLoginOut;
    }

    @action.bound
    setCurrentUser(currentUser: User.UserInfo) {
        this.currentUser = currentUser;
    }

    // 是否登录
    @computed get isLogin () {
        return !!(this?.userInfo?.accessToken)
    };

    // 获取token
    @computed get accessToken () {
        return (this.userInfo?.accessToken || '')
    };

    @action.bound // 登录action
    login(userInfo: Api.loginResponse): void {
        this.userInfo = userInfo;
        localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
    }

    @action.bound
    loginOut(){
        localStorage.removeItem('userInfo');
        this.userInfo = null;
        this.currentUser = null;
    }
}
const globalStore = new GlobalStore();
export {
    GlobalStore
}
export default globalStore;
